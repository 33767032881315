import React, { useEffect,useState}  from 'react'
import NewsItem from './NewsItem'
import Spinner from './Spinner';
import PropTypes from 'prop-types'
import InfiniteScroll from "react-infinite-scroll-component";



const News=(props)=>{
  const [articles,setArticles]= useState([]);
  const [loading,setLoading]= useState(true);
  const [page,setPage]= useState(1);
  const [totalResults,setTotalResults]= useState(0);
  // document.title= `${this.capitalizeFirstLetter(
  //   props.category)} - NewsApp`;

  const capitalizeFirstLetter=(string)=>{
    return string.charAt(0).toUpperCase()+string.slice(1);
  };
          
    const updateNews=async()=>{
      props.setProgress(0);
      let url=`https://newsapi.org/v2/top-headlines?country=${props.country}&category=${props.category}&apiKey=0792571beddd4152925ac597fb072007&page=${page}&pageSize=${props.pageSize}`;
      setLoading(true);
      let data= await fetch(url);
      props.setProgress(20);
      let parsedData= await data.json();
      props.setProgress(60);
      console.log(parsedData);
      setArticles(parsedData.articles);
      setTotalResults(parsedData.totalResults)
      setLoading(false);

      props.setProgress(100);
    }
    useEffect(()=>{
      updateNews();

    },[]);
    //async componentDidMount(){
      // let url=`https://newsapi.org/v2/top-headlines?country=${props.country}&category=${props.category}&apiKey=0792571beddd4152925ac597fb072007&page=${props.page}&pageSize=${props.pageSize}`;
      // this.setState({loading:true});
      // let data= await fetch(url);
      // let parsedData= await data.json();
      // console.log(parsedData);
      // this.setState({
      //   articles: parsedData.articles,
      //   totalResults:parsedData.totalResults,
      //   loading:false

      // });
    //   this.updateNews();

    // }
const handlePreviousClick=async()=>{
  // console.log("Previous");
  // let url=`https://newsapi.org/v2/top-headlines?country=${props.country}&category=${props.category}&apiKey=0792571beddd4152925ac597fb072007&page=${this.state.page
  // -1} &pageSize=${props.pageSize}`;
  // this.setState({loading:true});
  // let data= await fetch(url);
  // let parsedData= await data.json();
  // console.log(parsedData);
  // this.setState({
  //   page:this.state.page - 1,
  //   articles: parsedData.articles,
  //   loading:false
  // });
  // await this.setState({page:this.state.page -1});
  setPage(page-1);
  updateNews();


 };
 const handleNextClick= async()=>{
   // console.log("Next");
// if(!(this.state.page +1 >Math.ceil(this.state.totalResults/props.pageSize))){
//   let url=`https://newsapi.org/v2/top-headlines?country=${props.country}&category=${props.category}&apiKey=0792571beddd4152925ac597fb072007&page=${this.state.page
//     +1}&pageSize=${props.pageSize}`;
//     this.setState({loading:true});
//     let data= await fetch(url);
//     let parsedData= await data.json();
//     console.log(parsedData);
//     this.setState({
//       page:this.state.page +1,
//       articles: parsedData.articles,
//       loading : false,
//     });
// }
// await this.setState({page:this.state.page +1});
setPage(page+1);
updateNews();
 };
 const fetchMoreData = async() => {
 
   let url=`https://newsapi.org/v2/top-headlines?country=${props.country}&category=${props.category}&apiKey=0792571beddd4152925ac597fb072007&page=${page+1}&pageSize=${props.pageSize}`;
   setPage(page+1);
   setLoading(true);
   let data= await fetch(url);
   let parsedData= await data.json();
   console.log(parsedData);
   setArticles(articles.concat(parsedData.articles));
   setTotalResults(parsedData.totalResults);

};

    // console.log("inside render");
    return (
      <div className="container my-4">
    <h2 className="text-center" style={{margin:"25px 0px", marginTop:"90px"}}>
      NewsApp- Top {capitalizeFirstLetter(props.category)}{" "}
       Headlines
    </h2>
    {/* {this.state.loading && <Spinner/>} */}
    <InfiniteScroll
          dataLength={articles.length}
          next={fetchMoreData}
          hasMore={articles!==totalResults}
          loader={<Spinner/>}
        >
     <div className="container">
    <div className="row">
    { articles.map((element)=>{
    
        return( 
        <div className="col-md-4" key={element.url}>
         <NewsItem 
         title={element.title ? element.title:""} 
         description={element.description? element.description: ""}
         imageUrl={element.urlToImage}
         newsUrl={element.url}
         author={element.author}
         publishedAt={element.author}
         date={element.publishedAt}
         source={element.source.name}
         />
         </div>
        );
         })}
         </div>
         </div>
         </InfiniteScroll>
         {/* <div className="container d-flex justify-content-between my-4">
         <button
         disabled={this.state.page<=1} 
         type="button" 
         class="btn btn-secondary" 
         onClick={this.handlePreviousClick}
         >
         &larr; Previous
          </button>
         <button type="button" class="btn btn-secondary" onClick={this.handleNextClick}>
          Next &rarr;
          </button>
          </div> */}
      </div>
    )
  }



 News.defaultProps={
  country:'in',
  pageSize:8,
  category: "general",
};
News.propTypes={
country: PropTypes.string,
pageSize: PropTypes.number,
category: PropTypes.string

};
export default News;

